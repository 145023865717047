import Instance from "./instance";

export const GetSingleUser = async (campaignName, videoName, isDemo) => {
  try {
    let url = `/donors/${campaignName}/${videoName}`
    if (isDemo)
      url = `/demo_donors/${campaignName}/${videoName}`
    return await Instance.instance.get(url);
  } catch (error) {
    return error;
  }
};

export const AddUser = async (payload) => {
  try {
    return await Instance.instance.post("/user", payload);
  } catch (error) {
    return error;
  }
};

export const RenderUserVideo = async (payload) => {
  try {
    return await Instance.instance.post("/user/render", payload);
  } catch (error) {
    return error;
  }
};

export const CheckUserVideo = (campaignName, videoName) => {
  return Instance.instance.get(`/donors/renderStatus/${campaignName}/${videoName}`);
};

export const SendFeedBack = (campaignName, videoName, data) => {
  return Instance.instance.post(`/donors/feedback/${campaignName}/${videoName}`, data);
};

export const CheckoutSession = (payload) => {
  try {
    return Instance.instance.post("/payments/checkout", payload);
  } catch (error) {
    return error;
  }
}

export const PaymentSuccess = async (payload) => {
  try {
    return await Instance.instance.post("/user/render", payload);
  } catch (error) {
    return error;
  }
};

// PayPal
export const InitializePayment = async () => {
  try {
    return await Instance.instance.post("/payments/paypal/initialize")
  } catch (error) {
    return error;
  }
}

export const CreateOrder = async () => {
  try {
    return await Instance.instance.post("/payments/paypal/createOrder")
  } catch (error) {
    return error;
  }
}

export const OrderCompleted = async (orderId) => {
  return Instance.instance.post("/payments/paypal/capture", {
    orderId
  })
}

export const OrderCaptured = async (orderId) => {
  try {
    return await Instance.instance.post("/payments/paypal/captured/", {
      orderId
    })
  } catch (error) {
    return error;
  }
}
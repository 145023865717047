<template>
  <!-- card -->
  <div
    class="flex flex-col items-stretch justify-center px-2 py-2 rounded-xl shadow-xl bg-dark card-container"
  >
    <!-- video preview modal (with fade transition) -->
    <transition name="fade" mode="out-in">
      <template v-if="isAPILoading">
        <!-- heroicon - progress loader icon -->
        <svg
          class="relative animate-spin h-12 w-12 mx-auto text-light progress-circle"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </template>
      <template v-else>
        <div class="flex flex-col items-stretch justify-center">
          <video
            ref="previewVideo"
            width="100%"
            height="auto"
            controls
            autoplay
            muted
            @playing="onVideoPlaying"
            preload
            class="rounded-xl bg-black video-element"
            :poster="getCurrentPreviewVideoImageURL"
            :src="getCurrentPreviewVideoURL"
          ></video>
          <div class="flex flex-col sm:flex-row items-center justify-end my-2">
            <!-- copy video link button -->
            <button
              class="block bg-ghost-white rounded-sm w-52 py-2 m-2 transition-all duration-200 shadow-none hover:shadow-soft copy-link-button"
              copyTitle="Video preview link copied"
              :class="linkCopied ? 'show-tooltip' : ''"
              @click="copyVideoLink"
            >
              <div class="flex flex-row items-center justify-center">
                <span class="font-display font-bold text-left text-dark mr-2">
                  Copy Video Link
                </span>
                <!-- boxicon - link icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                >
                  <path
                    d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"
                  ></path>
                  <path
                    d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"
                  ></path>
                </svg>
              </div>
            </button>
            <!-- download video link button -->
            <button
              class="block bg-primary rounded-sm w-52 py-2 m-2 transition-all duration-200 shadow-none hover:shadow-soft download-video-button"
              @click="downloadVideoLink"
            >
              <!-- progress circle -->
              <template v-if="isVideoDownloading">
                <!-- heroicon - progress loader icon -->
                <svg
                  class="relative animate-spin h-5 w-5 mx-auto text-white progress-circle"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </template>
              <template v-else>
                <div class="flex flex-row items-center justify-center">
                  <span
                    class="font-display font-bold text-left text-white mr-2"
                  >
                    Download Video
                  </span>
                  <!-- boxicon - download icon -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    class="text-white fill-current"
                  >
                    <path d="M19 9h-4V3H9v6H5l7 8zM4 19h16v2H4z"></path>
                  </svg>
                </div>
              </template>
            </button>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
//store
import { mapGetters } from "vuex";

//services
import { GetSingleUser } from "@/services/user.service";
import { baseURL } from "@/services/config";
import backGroundImg from "../assets/img/secondary-main.jpg";

export default {
  name: "PreviewVideo",
  data() {
    return {
      user: null,
      isAPILoading: true,
      isVideoDownloading: false,
      linkCopied: false,
      isDemo: false,
    };
  },
  metaInfo() {
    return {
      title: "Give Proudly - Preview your Video",
      meta: [
        {
          name: "description",
          content: "Preview your personalized video through Give Proudly.",
        },
        {
          property: "og:title",
          content: "Give Proudly - Preview your Video",
        },
        { property: "og:site_name", content: "Give Proudly" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "users/getUserDetails",
    }),
    getCurrentPreviewVideoURL() {
      return this.user ? this.user.resultVideoDownloadLink : "";
    },
    getCurrentPreviewVideoImageURL() {
      return this.user ? this.user.targetVideoPreviewImage : "";
    },
  },
  async mounted() {
    if (this.$route.meta.isDemo) {
      this.isDemo = true;
    }
    // background image
    document.getElementById("backgroundImgId").style.background =
      "url(" + `${backGroundImg}`;
    (")");
    document.getElementById("backgroundImgId").style.backgroundSize = "cover";
    // shows a confirmation dialog when trying to refresh
    window.onbeforeunload = function (e) {
      if (this.isVideoDownloading) {
        e = e || window.event;
        if (e) {
          e.returnValue = "Are you sure you want to leave?";
        }
        // For Safari
        return "Are you sure you want to leave?";
        // return false;
      }
    };

    // get user details from API
    await this.getUserById(
      this.$route.params.campaignName,
      this.$route.params.videoName
    );
  },
  methods: {
    // populate target videos list
    async getUserById(campaignName, videoName) {
      try {
        this.isAPILoading = true;
        let { data } = await GetSingleUser(
          campaignName,
          videoName,
          this.isDemo
        );
        if (data.code == 200 || data.message == "success") {
          this.user = data.content;
          // then play the video after 3 seconds
          setTimeout(() => {
            this.$refs.previewVideo.play();
          }, 3000);
        }
      } catch {
        console.log("ERROR PV01");
      } finally {
        this.isAPILoading = false;
      }
    },
    onVideoPlaying() {
      let videoElement = this.$refs.previewVideo;
      if (videoElement) {
        videoElement.muted = false;
      }
    },
    // preview video modal functions
    openPreviewVideoModal() {
      // make the modal visible
      this.showVideoPreviewModal = true;
      // populate the video details into data parameters to be passed to the modal
      this.currentPreviewVideoTitle = this.user.targetVideoTitle;
      this.currentPreviewVideoURL = this.user.resultVideoDownloadLink;
    },
    closePreviewVideoModal() {
      // hide the modal
      this.showVideoPreviewModal = false;
      // reset the video details
      this.currentPreviewVideoURL = null;
      this.currentPreviewVideoTitle = null;
    },
    // copy link function
    copyVideoLink() {
      // Get the url of the video preview link
      // let copyLink = this.user.resultVideoPreviewLink;
      // get current url for now
      let copyLink = window.location.href;

      // create a text element to assign and copy the link
      let el = document.createElement("textarea");
      el.value = copyLink;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      // only show popover for three seconds
      this.linkCopied = true;
      // hide after 3 seconds
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },
    // function to download video (old)
    downloadVideo() {
      // Get the url of the video preview link
      let videoDownloadLink = this.user.resultVideoDownloadLink;

      var link = document.createElement("a");
      if (link.download !== undefined) {
        fetch(videoDownloadLink, {
          headers: new Headers({
            Origin: location.origin,
          }),
          mode: "cors",
        })
          .then((response) => response.blob())
          .then((blob) => {
            let blobUrl = window.URL.createObjectURL(blob);

            link.setAttribute("href", blobUrl);
            link.setAttribute("target", "_blank");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((e) => console.error(e));
      }
    },
    // function to download file using streams
    downloadVideoLink() {
      let path = `/download/${this.$route.params.campaignName}/${this.$route.params.videoName}`;
      if (this.isDemo)
        path = `/download/${this.$route.params.campaignName}/demos/${this.$route.params.videoName}`;
      window.open(baseURL + path, "_blank");
    },
  },
};
</script>

<style scoped>
.card-container .card-inner-container {
  height: 50vh;
}
/* video element styling */
.video-element {
  max-height: 80vh;
}
/* class for copy to clipboard button tooltip (add the text in the copyTitle attribute of the button) */
.copy-link-button {
  position: relative;
}
/* Tooltip container button */
.copy-link-button::after {
  content: attr(copyTitle);
  visibility: hidden;
  width: 100%;
  opacity: 0.6;
  font-size: 0.7rem;
  background-color: #333333;
  color: #ffffff;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 0.25rem);
  left: 0;
}
.copy-link-button::before {
  content: " ";
  visibility: hidden;
  position: absolute;
  top: -0.25rem; /* At the top of the tooltip */
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0.25rem;
  border-style: solid;
  opacity: 0.6;
  border-color: transparent #333333 transparent transparent;
}
/* Show the tooltip text when you the show-tooltip class is assigned */
.copy-link-button.show-tooltip::after,
.copy-link-button.show-tooltip::before {
  visibility: visible;
}
</style>
